<template>
  <div>
    <b-table-simple caption-top responsive bordered>
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">STT</b-th>
          <b-th class="text-center">Name</b-th>
          <b-th class="text-center">Drop rate buff %</b-th>
          <!-- <b-th class="text-center">Require Parts</b-th> -->
          <b-th class="text-center">Actions</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item, index) in items">
          <b-tr :key="item._id || index">
            <b-th class="text-center">{{ index + 1 }}</b-th>
            <b-th class="text-center">
              <!-- <ui-component :component-data="item.name" /> -->
              <render-translatable-text :component-data="item.name" />
            </b-th>
            <b-th class="text-center">{{ item.buff_effect }}</b-th>
            <!-- <b-th class="text-center">{{ item.require_parts }}</b-th> -->
            <b-th>
              <div class="d-flex justify-content-center">
                <b-button
                  variant="gradient-warning"
                  style="margin-right: 5px"
                  class="btn-icon"
                  @click="editItemSet(item._id)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  @click="confirmDelete(item._id)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </b-th>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import service from "../../service";

export default {
  props: {
    items: { type: Array, required: true },
  },
  methods: {
    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteEternal(id);
          }
        });
    },
    async deleteEternal(id) {
      await service.delete({ id: id });
      this.$emit("getList");
    },
    editItemSet(id) {
      this.$router.push({
        name: "item-set-detail",
        params: { set_id: id },
      });
    },
  },
};
</script>
