<template>
  <div>
    <b-button variant="primary" @click="isModalShow=true">
    <b-spinner v-if="isLoading" small class="mr-50" />
    <feather-icon v-else icon="PlusIcon" class="mr-50" />
    <span class="align-middle">Add New</span>
    </b-button>
    <b-modal
      v-model="isModalShow"
      title="Create new item"
      hide-footer
      size="lg" modal-class="modal-primary"
    >
      <validation-observer
        ref="createForm"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <validation-provider
            name="Name"
            v-slot="validationContext"
            :rules="{ required: true}"
          >
            <b-form-group
              label="Item Name"
              label-for="h-item-name"
            >
              <b-form-input
                v-model="itemData.name.translations[0].value"
                id="item-name"
                name="item-name"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="input-name-feedback"
              />
              <b-form-invalid-feedback id="input-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Description"
            label-for="h-item-description"
          >
            <b-form-input
              v-model="itemData.description.translations[0].value"
              id="item-description"
              name="item-description"
              type="text"
            />
          </b-form-group>
          <validation-provider
            name="Drop rate buff"
            v-slot="validationContext"
            :rules="{ required: true, min: 0 }"
          >
            <b-form-group
              label="Drop rate buff"
              label-for="h-buff-effect"
            >
              <b-input-group size="lg" prepend="%">
                <b-form-input 
                  v-model="itemData.buff_effect" 
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-buff-effect-feedback"
                  type="number"
                />
              </b-input-group>
              <b-form-invalid-feedback id="input-buff-effect-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <validation-provider
            name="require parts"
            v-slot="validationContext"
            :rules="{ required: true, min: 0 }"
          >
            <b-form-group
              label="Require parts"
              label-for="h-require-parts"
            >
              <b-form-input 
                v-model="itemData.require_parts" 
                :state="getValidationState(validationContext)"
                aria-describedby="input-require-parts-feedback"
                type="number"
              />
              <b-form-invalid-feedback id="input-require-parts-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->
        </b-form>
        <div class="d-flex justify-content-between my-2">
          <b-button variant="danger" @click="cancel">Cancel</b-button>
          <b-button type="submit" variant="primary" @click="create">Create</b-button>
        </div>
      </validation-observer>
      
      <hr>
      
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import service from "../../service";
import defaultObject from "../../defaultObject"
export default {
  components:{
    ValidationProvider,
    ValidationObserver
  },
  data(){
    return{
      isLoading: false,
      isModalShow: false,
      name: null,
      itemData: JSON.parse(JSON.stringify(defaultObject)),
    }
  },
  methods:{
    async create() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        this.isLoading = true;
        let new_item = await service.create(JSON.stringify(this.itemData));
        this.isLoading = false;
        if (new_item) {
          this.$store.dispatch('pushSuccessNotify', { text: 'Set item created!' })
          this.$emit("create");
          this.cancel();
        }
      }
    },
    cancel(){
      this.isModalShow = false;
      this.itemData = JSON.parse(JSON.stringify(defaultObject));
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>