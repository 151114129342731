export default {
  name: {
    meta: {
      default_lang: "en"
    },
    type: "translatable-text",
    value: null,
    translations:[{
      language: "vi",
      value: null
    }]
  },
  description: {
    meta: {
      default_lang: "en"
    },
    type: "translatable-text",
    value: null,
    translations:[{
      language: "vi",
      value: null
    }]
  },
  buff_effect: null,
  require_parts: 3
}