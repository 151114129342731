<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Item sets" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-1">
        <b-row>
          <b-col cols="10">
            <h2 class="mb-2">Item Sets List</h2>
          </b-col>
          <b-col cols="2">
            <div class="text-center">
              <item-set-create-form :display_order="total_items" @create="createSetItem" />
            </div>
          </b-col>
        </b-row>
      </section>
    </b-media>
    <b-card>
      <item-sets-table 
        @getList="getList" 
        :items="items"
      />
    </b-card>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>
<script>
import service from '../service'
import ItemSetsTable from './_components/ItemSetsTable.vue'
import ItemSetCreateForm from './_components/ItemSetCreateForm.vue'
export default {
  components: {
    ItemSetsTable,
    ItemSetCreateForm
  },
  data(){
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 15,
      items: [],
      editLanguage: null,
    }
  },
  watch:{
    current_page() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
      });
      if(response){
        this.items = response.list;
        this.total_items = response.total;
      }
    },
    createSetItem(){
      this.getList();
    }
  },
}
</script>